<template>
  <div>
    <input
      v-if="optional"
      v-model="checked"
      type="checkbox"
    >
    <ProductCustomizableOptions
      v-else-if="product?.options?.length"
      :product="product"
      @submit="addCustomizableOptions"
    />
    <slot
      name="oos"
      v-bind="{ oos }"
    />
  </div>
</template>

<script lang="ts" setup>
import type { MagentoSimpleProduct, MagentoSimpleProductCartItemInput } from '~layers/app/types/magento'

export interface Props {
  product: MagentoSimpleProduct
  optional?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  optional: false,
})

const checked = ref<boolean>(false)

const customizableOptions = ref(undefined)
const addCustomizableOptions = (data) => {
  customizableOptions.value = data
  selected(false)
}

const emit = defineEmits<{
  (event: 'selected', data: Record<string, MagentoSimpleProductCartItemInput>): void
  (event: 'submit'): void
}>()

watch(checked, (val: boolean) => {
  selected(!val)
})

const oos = computed(() =>
  props.product.stock_status === 'OUT_OF_STOCK' ? [props.product] : [],
)

onMounted(() => {
  if (!props.optional) {
    selected(false)
  }
})

const cartItemData = computed<any>(() => {
  return {
    data: {
      quantity: 1,
      sku: props.product.sku,
    },
    customizable_options: customizableOptions.value,
  }
})

const selected = (reset: boolean | undefined) => {
  emit('selected', {
    [props.product.sku]: reset
      ? null
      : cartItemData.value,
  })

  if (cartItemData.value?.customizable_options) {
    emit('submit')
  }
}
</script>
